<template>
  <div class="information">
    <page-title :title="'Данные'" ></page-title>
      <v-card class="pa-3 rounded-0 flat custom__card">
        <data-content></data-content>
      </v-card>
  </div>
</template>
<script>
import PageTitle from '../components/main/PageTitle'
import DataContent from '../components/data/DataContent'
export default {
  components: {
    PageTitle,
    DataContent,
  },
  methods: {
  }
}
</script> 
